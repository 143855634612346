import { useQuery, useInfiniteQuery } from "@tanstack/vue-query"
import type { TrashItem } from "#build/interfaces/trashItem"

const config = useRuntimeConfig()

async function getNumberOfPicsTakenByUser(userId: string): Promise<number> {
  const path = `trashitempb?pbjson->>userId=eq.${userId}`
  const url = config.public.SERVICES_PGRST_URL_PREFIX + path
  const res = await $fetch.raw(url, {
    headers: {
      'Range-Unit': 'items',
      'Range': '0-0',
      'Prefer': 'count=exact',
    },
  })
  const contentRange = res._data ? res.headers.get('content-range') : null
  return contentRange ? Number(contentRange.split('/').slice(-1)[0]) : 0
}

async function getPicsTakenByUser(userId: string | undefined, range: string): Promise<TrashItem[]> {
  const path = `trashitempb?pbjson->>userId=eq.${userId}&order=ts.desc`
  const url = config.public.SERVICES_PGRST_URL_PREFIX + path
  const res = await $fetch.raw(url, {
    headers: {
      'Range-Unit': 'items',
      'Range': range,
      'Prefer': 'count=exact',
    },
  })
  if (!res._data)
    return []
  return res._data as Array<TrashItem>
}

export default function useUserPics(userId: string) {
  const itemsPerPage = 8
  const initialItems = 15
  const initialRange = `0-${initialItems - 1}`

  const { data: picsTakenData } = useQuery({
    queryKey: ['userPicsTaken', userId],
    queryFn: () => getNumberOfPicsTakenByUser(userId),
  })

  const picsTaken = computed(() => picsTakenData.value || 0)

  const {
    data: picturesData,
    isPending,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage
  } = useInfiniteQuery({
    queryKey: ['userPictures', userId],
    queryFn: ({ pageParam = initialRange}) => getPicsTakenByUser(userId, pageParam as string),
    getNextPageParam: (_, allPages) => {
      const totalItems = picsTaken.value
      const currentPage = allPages.length
      if (totalItems == 0) return null

      const rangeStart = initialItems + (currentPage - 1) * itemsPerPage
      const rangeEnd = Math.min(rangeStart + itemsPerPage - 1, totalItems - 1)
      if (rangeStart >= totalItems) return null
      
      return `${rangeStart}-${rangeEnd}`
    },
    initialPageParam: initialRange,
  })

  const pictures = computed<TrashItem[]>(() => picturesData.value?.pages.flat() || [])

  return {
    pictures,
    picsTaken,
    isPending,
    nextPage: { hasNextPage, fetchNextPage, isFetchingNextPage },
  }
}